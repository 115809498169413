import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
const axios = require('axios')
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import diffusion from '../data/data-july-2024.json'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import {
  LineChart,
  Legend,
  ResponsiveContainer,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import LinkBack from '../components/link-back'
const moment = require('moment')

class TubeTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      maxNo: 0,
      minNo: 0,
    }
  }

  render() {
    let site = this.props.pathContext.name

    let path = this.props.location.pathname
    if (path[path.length - 1] === '/') {
      path = path.slice(0, -1)
    }

    const urlChunks = path.split('/')
    const code = decodeURI(urlChunks[urlChunks.length - 1])
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const tube = diffusion.find(t => t['Ref'] === code)

    console.log(tube)

    if (!this.state.data && tube) {
      let data = []
      let maxNo = 0
      let minNo = 10000
      for (let index = 2011; index <= 2023; index++) {
        if (tube['' + index + 'c'] !== '') {
          let item = {
            name: index,
            value: tube['' + index + 'c'],
          }

          if (parseFloat(tube['' + index + 'c']) > maxNo) {
            maxNo = parseFloat(tube['' + index + 'c'])
          }

          if (parseFloat(tube['' + index + 'c']) < minNo) {
            minNo = parseFloat(tube['' + index + 'c'])
          }

          data.push(item)
        }
      }
      this.setState({
        data,
        maxNo: Math.ceil(maxNo / 10) * 10,
        minNo: Math.floor(minNo / 5) * 5,
      })
    }

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={`${site} monitoring tube | ${siteTitle}`} />
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />{' '}
            <h1>Monitoring Tube: {site}</h1>
          </div>

          <Container>
            <LinkBack
              to={'/data-hub/diffusion-tubes#map'}
              title={'Back to diffusion tubes map'}
            />
          </Container>
          {tube && (
            <div className="data-hub">
              <Container className="news mt-5 mb-5">
                <h2 className="mb-5">Site information</h2>
                <Row>
                  <Col sm={12} lg={6}>
                    <Row>
                      <Col sm={12}>
                        <h4>Tube reference</h4>
                        <p>{tube['Ref']}</p>
                      </Col>
                      {tube['LA'] && (
                        <Col sm={12}>
                          <h4>Local authority</h4>
                          <p>{tube['LA']}</p>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>Survey</h4>
                        <p>
                          {tube['Survey'] === 'CAP'
                            ? 'Clean Air Plan'
                            : 'Local Air Quality Management'}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      {tube['ID'] && (
                        <Col sm={12}>
                          <h4>Site ID</h4>
                          <p>{tube['ID']}</p>
                        </Col>
                      )}
                      <Col sm={12}>
                        <h4>Site type</h4>
                        <p>{tube['Type']}</p>
                      </Col>
                      <Col sm={12}>
                        <h4>Location</h4>
                        <p>
                          {tube.Lat}, {tube.Lon}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>Distance to relevant exposure</h4>
                        <p>{tube['DistExp'] ? tube['DistExp'] + 'm' : '-'}</p>
                      </Col>
                      <Col sm={12}>
                        <h4>Distance to kerb of nearest road</h4>
                        <p>{tube['DistRoad'] ? tube['DistRoad'] + 'm' : '-'}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>Tube collated with continuous analyser</h4>
                        <p>{tube['ContAnal'] === 'YES' ? 'Yes' : 'No'}</p>
                      </Col>
                      <Col sm={12}>
                        <h4>Height</h4>
                        <p>{tube['Height'] + ' m' || '-'}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>In air quality management area</h4>
                        <p>
                          {tube['AQM'] === ''
                            ? '-'
                            : tube['AQM'] === 'YES'
                            ? 'Yes'
                            : 'No'}
                        </p>
                      </Col>
                      {tube['Survey'] === 'CAP' && (
                        <Col sm={12}>
                          <h4>
                            Site Compliant with Limit Value Siting Criteria?
                          </h4>
                          <p>{tube['ComLVS'] === 'YES' ? 'Yes' : 'No'}</p>
                        </Col>
                      )}
                      {tube['Survey'] === 'CAP' && (
                        <Col sm={12}>
                          <h4>
                            Latest air quality target (40μg/m<sup>3</sup> or
                            below
                            <sup>*</sup>)
                          </h4>
                          <p>
                            {parseFloat(tube['2023c']) < 40.5
                              ? 'Target achieved'
                              : 'Target not achieved'}
                          </p>
                          <p>
                            <sup>*</sup>For the purposes of Clean Air Plan
                            monitoring, the government rounds nitrogen dioxide
                            annual average (mean) measurements up or down to the
                            nearest whole number when considering whether the
                            legal limit of 40μg/m<sup>3</sup> has been exceeded.
                            So figures of 40.4μmg/m<sup>3</sup> or below would
                            be considered to be within the legal limit and
                            40.5μg/m
                            <sup>3</sup> would be above the limit.
                          </p>
                        </Col>
                      )}
                      {tube['Survey'] === 'LAQM' && (
                        <Col sm={12}>
                          <h4>
                            Latest air quality target (Below 40μg/m<sup>3</sup>
                            <sup>*</sup>)
                          </h4>
                          <p>
                            {parseFloat(tube['2023c']) < 40
                              ? 'Target achieved'
                              : 'Target not achieved'}
                          </p>
                          <p>
                            <sup>*</sup>For the purposes of Local Air Quality
                            Management monitoring, the government classes annual
                            average (mean) measurements of 40.0μg/m<sup>3</sup>{' '}
                            and over to be above the legal limit.
                          </p>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col sm={12}>
                        <h4>
                          2023 air quality (μg/m<sup>3</sup>)
                        </h4>
                        <p>{tube['2023c'] || '-'}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>2023 Data capture</h4>
                        <p>{tube['2023d'] ? tube['2023d'] + '%' : '-'}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm={12} lg={{ size: 6, offset: 0 }}>
                    <LoadScript
                      id="script-loader"
                      googleMapsApiKey="AIzaSyA1yf9mWk13ntuoG5Lia0P-i3UH_igYi-A"
                    >
                      <GoogleMap
                        className="map"
                        id="example-map"
                        mapContainerStyle={{
                          height: '300px',
                          width: '100%',
                        }}
                        zoom={15}
                        center={{
                          lat: Number(tube.Lat),
                          lng: Number(tube.Lon),
                        }}
                      >
                        <Marker
                          position={{
                            lat: Number(tube.Lat),
                            lng: Number(tube.Lon),
                          }}
                        />
                      </GoogleMap>
                    </LoadScript>
                  </Col>
                </Row>
              </Container>
              {this.state.data && this.state.data.length > 0 && (
                <Container className="mt-5 mb-5">
                  <h2 className="mb-4">
                    Measured annual average NO2 concentrations
                  </h2>
                  <ResponsiveContainer width="100%" minHeight={300}>
                    <LineChart
                      data={this.state.data}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 30,
                        bottom: 20,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis
                        unit="µg/m³"
                        type="number"
                        tickFormatter={num => num.toPrecision(3)}
                        domain={[this.state.minNo, this.state.maxNo || 100]}
                      />{' '}
                      <Tooltip />
                      <Line
                        strokeWidth={5}
                        type="monotone"
                        dataKey="value"
                        stroke="#5295AD"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Container>
              )}
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

export default TubeTemplate

export const pageQuery = graphql`
  query TubePageBySlug {
    site {
      siteMetadata {
        title
      }
    }
  }
`
